import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "./assets/css/Main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css"

import Home from "./pages/Home";
import Nav from "./components/Content";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NewsLetter from "./components/NewsLetter";
import SuccessStories from "./pages/SuccessStories";
import ScholarshipEnroll from "./pages/ScholarshipEnroll";
import TermsAndConditions from "./pages/TermsAndConditions";
import MainLayout from "./components/MainLayout";  // Import the layout

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Redirect root to /Program */}
          <Route path="/" element={<Navigate to="/Program" />} />

          {/* Routes with MainLayout */}
          <Route
            path="/"
            element={
              <MainLayout>
                <Nav />
              </MainLayout>
            }
          />
          <Route
            path="/:id"
            element={
              <MainLayout>
                <Nav />
              </MainLayout>
            }
          />
          <Route
            path="/SuccessStories"
            element={
              <MainLayout>
                <SuccessStories />
              </MainLayout>
            }
          />
          <Route
            path="/ScholarshipEnroll"
            element={
              <MainLayout>
                <ScholarshipEnroll />
              </MainLayout>
            }
          />

          {/* TermsAndConditions route without MainLayout */}
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
