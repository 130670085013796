import React from "react"

const TermsAndConditions =()=>{
return(
    <>
    <React.Fragment>
        <div>
        Terms & Condition will comming soon...
        </div>
    </React.Fragment>
    </>
)
}

export default TermsAndConditions