import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ScholarshipEnroll from '../pages/ScholarshipEnroll';
import { useNavigate } from 'react-router-dom';
const Nav = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [contentData, setContentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log(id)
      if(id==='TermsAndConditions')
      {
        
        navigate('/TermsAndConditions');
      }
      try {
        const res = await axios.get(`${process.env.REACT_APP_NAIDIP_FOUNDATION_URL}/api/auth/cms/get-contentByUrl/${id}`);
        setContentData(res.data);
        setLoading(false);
        console.log(res)
      } catch (error) {
        setError(error);
        setLoading(false);
        
      }
    };
    fetchData();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <div className="spacing-form">
        <Row className="spacing-form">
          <Col md="4">
            <Row className="margin-top">
              <Col md="6" className="padding-none">
                <div className="grid-spacing1">
                  <strong><Link to="/Program">Programme</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing2">
                  <strong><Link to="/Associates">Associates</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing2">
                  <strong><Link to="/Benefits">Benefits</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing1">
                  <strong><Link to="/Eligibility">Eligibility</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing1">
                  <strong><Link to="/Criteria">Selection Criteria</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing2">
                  <strong><Link to="/ScholarshipEnroll">Enroll Now</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing2">
                  <strong><Link to="/Faq">FAQ</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing1">
                  <strong><Link to="/Contact">Contact</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing1">
                  <strong><Link to="/Disclaimer">Disclaimer</Link></strong>
                </div>
              </Col>
              <Col md="6" className="padding-none">
                <div className="grid-spacing2">
                  <strong><Link to="/SuccessStories" target="_blank">Success Stories</Link></strong>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="8">
            <div className="table-responsive">
              {id === 'ScholarshipEnroll' ? (
                <ScholarshipEnroll />
              ) : (
                contentData.ContentUpload ? (
                  <div dangerouslySetInnerHTML={{ __html: contentData.ContentUpload }} />
                ) : null
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Nav;
