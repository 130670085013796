import React from 'react';
import Header from './Header';
import Footer from './Footer';
import NewsLetter from './NewsLetter';
import Home from '../pages/Home';
import ScrollUpButton from "./ScrollUpButton"; // Import the ScrollUpButton
const MainLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <Home />
      <div>{children}</div>
      <NewsLetter />
      <Footer />
      <ScrollUpButton /> {/* Add the ScrollUpButton here */}
    </div>
  );
};

export default MainLayout;
